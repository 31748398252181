// Here you can add other styles
@keyframes FadeAnim {
  0% {
     opacity: 0;
  }
  100% {
     opacity: 1;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important
}
  